import React from "react"
import PropTypes from "prop-types"
import HeadingText from "@components/common/HeadingText"
import UniversalLink from "@components/common/UniversalLink"
import DisplayText from "@components/common/DisplayText"
import BrandButton from "@components/common/BrandButton"
import * as c from "@utilities/constants"

import "./style.scss"

const HomepageDepartmentSection = props => {
  return (
    <div className="specialty-section">
      <div className="specialty-section__wrapper">
        <HeadingText
          className="specialty-section__title mb-40px"
          color="light"
          size="md"
          text={props.title}
        />
      </div>
      <ul className="specialty-section__list">
        {props.departments.map((department, index) => {
          return (
            <li className="li-reset">
              <UniversalLink
                key={`specialty-section__list--${index}`}
                href={`${(props.cta || {}).url}?${c.DEPARTMENT_PARAM_KEY}=${
                  department.id
                }#${c.DIRECTORY_TABLE_ANCHOR}`}
              >
                <DisplayText
                  className="specialty-section__link text-link--green-hover"
                  size="xxl"
                  text={department.name}
                  color="light"
                />
              </UniversalLink>
            </li>
          )
        })}
      </ul>
      {props.cta && (
        <BrandButton
          color="tertiary"
          text={props.cta.title}
          href={props.cta.url}
        />
      )}
    </div>
  )
}

HomepageDepartmentSection.propTypes = {
  cta: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.string,
  departments: PropTypes.array,
}
HomepageDepartmentSection.defaultProps = {
  departments: [],
}

export default HomepageDepartmentSection
