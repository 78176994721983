import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import HeadingText from "../common/HeadingText"
import BodyText from "../common/BodyText"
import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"
import language from "@language"
import { truncateText } from "@utilities/copyUtils"
import CustomImage from "@components/common/CustomImage"
import IconLinkButton from "@components/common/IconLinkButton"
import { fullDateFormat } from "@utilities/copyUtils"

import "./style.scss"
import UniversalLink from "../common/UniversalLink"

const NewsCard = props => {
  const formattedDate = fullDateFormat(props.language, props.date)

  return (
    <div className="news-card">
      <CustomImage
        className="news-card__image responsive-image"
        src={props.image.sourceUrl}
        alt={props.image.altText}
      />
      <div className="news-card__wrapper">
        <HeadingText
          className={`news-card__category ${props.category.acfCategory.color}-text`}
          size="xxxs"
          text={props.category.name}
          element="p"
        />
        <UniversalLink href={props.uri}>
          <HeadingText
            className="news-card__title text-link"
            color="primary"
            text={props.title}
            size="sm"
          />
        </UniversalLink>
        {formattedDate && (
          <BodyText
            className="news-card__date"
            color="black"
            size="xs"
            text={formattedDate}
          />
        )}
        {props.description && (
          <BodyText
            className={classnames("news-card__description")}
            size="sm"
            color="dark-gray"
            text={truncateText(props.description)}
          />
        )}

        <IconLinkButton
          className="news-card__cta"
          icon={<ChevronRight />}
          uri={props.uri}
          text={language[props.language].read_more}
        />
      </div>
    </div>
  )
}

NewsCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
  }),
  date: PropTypes.string,
  category: PropTypes.shape({
    name: PropTypes.string,
    acfCategory: PropTypes.shape({
      color: PropTypes.string,
    }),
  }),
  description: PropTypes.string,
  uri: PropTypes.string,
  language: PropTypes.string,
}
NewsCard.defaultProps = {
  language: "es",
  category: {
    acfCategory: {},
  },
}

export default NewsCard
