import React from "react"
import PropTypes from "prop-types"
import Markdown from "markdown-to-jsx"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import { preFilterMarkdown } from "@utilities/markdownUtils"

import "./style.scss"

const SplitTextSection = props => {
  return (
    <div className="split-text-section">
      <div className="page-wrap content-spacing--xl">
        <HeadingText
          className="split-text-section__title mb-40px"
          color="dark"
          size="md"
          text={props.sectionTitle}
        />
        <div className="split-text-section__split-wrapper row">
          <div className="split-text-section__primary-left col-lg-6">
            <BodyText
              className="split-text-section__primary-left-top"
              size="xxl"
              color="primary"
              text={props.primaryText}
            />
            <BodyText
              className="split-text-section__primary-left-bottom"
              size="xxl"
              color="primary-dark"
              text={props.primaryDarkText}
            />
          </div>
          <div className="split-text-section__body col-lg-5 offset-lg-1">
            {props.bodyText && (
              <Markdown
                options={{
                  overrides: {
                    p: {
                      component: BodyText,
                      props: {
                        color: "dark-gray",
                        size: "lg",
                      },
                    },
                  },
                }}
              >
                {preFilterMarkdown(props.bodyText)}
              </Markdown>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

SplitTextSection.propTypes = {
  sectionTitle: PropTypes.string,
  primaryText: PropTypes.string,
  primaryDarkText: PropTypes.string,
  bodyText: PropTypes.string,
}
SplitTextSection.defaultProps = {}

export default SplitTextSection
