import React from "react"
import PropTypes from "prop-types"

import "./style.scss"
import MaskedImage from "@components/common/MaskedImage"
import DualButton from "@components/common/DualButton"
import BrandButton from "@components/common/BrandButton"
import DisplayText from "@components/common/DisplayText"

const HomepageHero = props => {
  return (
    <div className="homepage-hero">
      <div className="homepage-hero__image">
        <MaskedImage
          imageClassName="full-bleed-image"
          sourceUrl={(props.heroImage || {}).sourceUrl}
          altText={(props.heroImage || {}).altText}
          width={1440}
          color="#000"
          opacity={0.3}
          imageFocus="full-bleed"
          optionalVideoUrl={(props.heroVideo || {}).mediaItemUrl}
          optionalVideoMimeType={(props.heroVideo || {}).mimeType}
        />
      </div>
      <div className="page-wrap">
        <div className="homepage-hero__wrapper">
          <div className="row">
            <div className="col-md-8 col-xl-7">
              <DisplayText
                element="h1"
                className="homepage-hero__title mb-40px"
                size="lg"
                color="light"
                text={props.heroTitle}
              />

              <DualButton
                button1={
                  props.heroCta1 && (
                    <BrandButton
                      size="md"
                      color="secondary"
                      text={props.heroCta1.title}
                      href={props.heroCta1.url}
                    />
                  )
                }
                button2={
                  props.heroCta2 && (
                    <BrandButton
                      size="md"
                      color="tertiary"
                      text={props.heroCta2.title}
                      href={props.heroCta2.url}
                    />
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HomepageHero.propTypes = {
  heroTitle: PropTypes.string,
  heroImage: PropTypes.shape({
    sourceUrl: PropTypes.string,
    altText: PropTypes.string,
  }),
  heroVideo: PropTypes.shape({
    mediaItemUrl: PropTypes.string,
    mimeType: PropTypes.string,
  }),
  heroCta1: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  heroCta2: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
}
HomepageHero.defaultProps = {
  heroImage: {},
  heroVideo: {}
}

export default HomepageHero
