import React from "react"
import PropTypes from "prop-types"

import HeadingText from "../common/HeadingText"
import BodyText from "../common/BodyText"
import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"

import LocationTag from "@components/common/LocationTag"
import CustomImage from "@components/common/CustomImage"
import UniversalLink from "@components/common/UniversalLink"

import language from "@language"
import { cityStateZip } from "@utilities/copyUtils"
import IconLinkButton from "../common/IconLinkButton"
import "./style.scss"

const LocationCard = props => {
  return (
    <div className="location-card">
      <div className="location-card__image-container">
        <LocationTag className="location-card__tag" typeLabel={props.typeName} />

        <CustomImage
          className="location-card__image image-cover"
          alt={(props.image || {}).altText}
          src={(props.image || {}).sourceUrl}
        />
      </div>
      <div className="location-card__content">
        <div className="top">
          <UniversalLink className="text-link--green-hover" href={props.uri}>
            <HeadingText
              className="location-card__title"
              color="primary"
              size="sm2"
              text={
                <>
                  <p>
                    {props.titlePrefix}
                  </p>
                  <p className="primary-dark-text location-card__title d-inline">
                    {props.title}
                  </p>
                </>
              }
            />
          </UniversalLink>

          <div className="location-card__address mb-20px">
            {props.streetAddress && (
              <BodyText color="dark-gray" size="sm">
                {props.streetAddress}
              </BodyText>
            )}
            <BodyText
              color="dark-gray"
              size="sm"
              text={cityStateZip(
                props.city,
                props.stateAbbreviation,
                props.zipCode
              )}
            />
          </div>
          <div className="location-card__phone">
            {props.telephone && (
              <BodyText color="dark-gray" size="sm">
                {props.telephone}
              </BodyText>
            )}
          </div>
        </div> 
        <IconLinkButton
          icon={<ChevronRight />}
          uri={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(props.streetAddress)}&query_place_id=${props.placeId}`}
          text={language[props.language].directions}
        />
      </div>
    </div>
  )
}

LocationCard.propTypes = {
  title: PropTypes.string,
  titlePrefix: PropTypes.string,
  telephone: PropTypes.string,
  streetAddress: PropTypes.string,
  city: PropTypes.string,
  stateAbbreviation: PropTypes.string,
  language: PropTypes.oneOf(["es"]),
  zipCode: PropTypes.string,
  uri: PropTypes.string,
  placeId: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
  }),
  typeName: PropTypes.string
}
LocationCard.defaultProps = {
  language: "es",
  titlePrefix: "Doctors' Center Hospital",
  title: "Location",
}

export default LocationCard
