import React from "react"
import PropTypes from "prop-types"
import CustomImage from "@components/common/CustomImage"
import classnames from "classnames"

import "./style.scss"

const MaskedImage = props => {
  return (
    <div className={classnames("masked-image", props.className)}>
      <div
        className={classnames(
          "masked-image__wrapper",
          `masked-image__${props.imageFocus}`
        )}
      >
        <div
          className="masked-image__mask"
          style={{ backgroundColor: props.color, opacity: props.opacity }}
        ></div>
        {
          props.optionalVideoUrl &&
          <>
            <video className={classnames("masked-image__video", props.imageClassName)} poster={props.sourceUrl} autoPlay loop muted controls={false}>
              <source src={props.optionalVideoUrl} type={props.optionalVideoMimeType} />
              <track src="/video-captions/homepage-hero_en.vtt" kind="captions" srclang="en" label="english_captions"></track>
            </video>
            <CustomImage
              alt={props.altText}
              className={classnames("masked-image__video-mobile", props.imageClassName)}
              src={props.sourceUrl}
              width={props.width}
            />
          </>
        }
        {
          !props.optionalVideoUrl && <CustomImage
            alt={props.altText}
            className={classnames("masked-image__image", props.imageClassName)}
            src={props.sourceUrl}
            width={props.width}
          />
        }

      </div>
    </div>
  )
}

MaskedImage.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  altText: PropTypes.string,
  sourceUrl: PropTypes.string,
  width: PropTypes.number,
  color: PropTypes.string,
  opacity: PropTypes.number,
  imageFocus: PropTypes.oneOf(["full-bleed", "center", "top"]),
  optionalVideoUrl: PropTypes.string,
  optionalVideoMimeType: PropTypes.string
}
MaskedImage.defaultProps = {
  imageFocus: "top",
  imageClassName: "responsive-image",
}

export default MaskedImage
