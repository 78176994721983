import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as HospitalIcon } from "@images/icons/hospital-icon-white.svg"
import { ReactComponent as ClinicIcon } from "@images/icons/clinic-icon-white.svg"
import { ReactComponent as EmergencyIcon } from "@images/icons/emergency-icon-white.svg"

import classnames from "classnames"

import "./style.scss"
const LocationTag = props => {
  const renderIcon = () => {
    console.log(props.typeLabel)
    switch (props.typeLabel) {
      case "Clínica":
        return <ClinicIcon height="20px" width="20px" className="location-tag--icon" />
      case "Urgencias":
        return <EmergencyIcon
          height="20px"
          width="20px"
          className="location-tag--icon"
        />
      default:
        return <HospitalIcon
          height="20px"
          width="20px"
          className="location-tag--icon"
        />



    }
  }

  return (
    <div className={classnames("location-tag", props.className)}>
      {renderIcon()}
      <span className="location-tag--text">{props.typeLabel}</span>
    </div>
  )
}

LocationTag.propTypes = {
  className: PropTypes.string,
  typeLabel: PropTypes.string,
}
LocationTag.defaultProps = {}

export default LocationTag
