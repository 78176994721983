import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"
import gql from "graphql-tag"
import withPreview from "@components/withPreview"
import language from "@language"

import Layout from "@components/layout"
import SEO from "@components/seo"
import PageCarousel from "@components/PageCarousel"
import LocationCard from "@components/LocationCard"
import BrandButton from "@components/common/BrandButton"
import HeadingText from "@components/common/HeadingText"
import FadeInSection from "@components/common/FadeInSection"

import { filterPageForTemplate } from "@templates/utilities/templateUtils"
import HomepageHero from "@templates/homepage/components/HomepageHero"
import SplitTextSection from "./components/SplitTextSection"
import SplitTextImageSection from "./components/SplitTextImageSection"
import HomepageDepartmentSection from "./components/HomepageDepartmentSection"

import "./style.scss"
import NewsCard from "../../components/NewsCard"
import ThreeColumnGrid from "../../components/common/ThreeColumnGrid"

const Homepage = props => {
  const page =
    !!props.preview && !!props.preview.page
      ? props.preview.page.preview.node
      : filterPageForTemplate(
        props.data.allWpPage.edges,
        props.pageContext.wordpressUri
      )

  const recentNewsPosts = props.data.allWpNewsPost.edges.map(e => e.node)

  const newsItems =
    page.acfHomepage.hnewsCarousel.mode === "manual"
      ? page.acfHomepage.hnewsCarousel.news.filter(n => n.acf.publishFinal) // publish-final only
      : recentNewsPosts

  return (
    <Layout isHomepage={true}>
      <SEO title={page.acfPageMeta.metaTitle} />
      <div className="homepage">
        <HomepageHero
          heroTitle={page.acfHomepage.hpageHero.title}
          heroImage={page.acfHomepage.hpageHero.image}
          heroVideo={page.acfHomepage.hpageHero.video}
          heroCta1={page.acfHomepage.hpageHero.cta1}
          heroCta2={page.acfHomepage.hpageHero.cta2}
        />
        <div className="homepage__carousel-section bg-light-gray">
          <div className="page-wrap content-spacing--lg">
            <ThreeColumnGrid
              overline={language[props.language].locations}
              title={page.acfHomepage.locationCarousel.title}
              cta={<BrandButton
                className="homepage__cta--desktop mt-100px mb-20px"
                href={page.acfHomepage.locationCarousel.cta.url}
                text={page.acfHomepage.locationCarousel.cta.title}
              />}
              items={(page.acfHomepage.locationCarousel.locations || [])
                .filter(l => l.acf.publishFinal)
                .map((location, index) => {
                  return (
                    <LocationCard
                      key={`homepage-location-card--${index}`}
                      title={location.title}
                      telephone={location.acf.telephone}
                      city={location.acf.city}
                      stateAbbreviation={location.acf.stateAbbreviation}
                      streetAddress={location.acf.streetAddress}
                      typeName={(location.acf.type || {}).name}
                      titlePrefix={(location.acf.type || {}).description}
                      zipCode={location.acf.zipCode}
                      placeId={location.acf.map.placeId}
                      uri={location.uri}
                      image={location.acf.locationImage}
                    />
                  )
                })} />

          </div>
        </div>
        <div className="homepage__custom-blur-background">
          <div className="page-wrap">
            <div className="oval oval-0"></div>
            <div className="oval oval-1"></div>
            <div className="oval oval-2"></div>
            <div className="oval oval-3"></div>
            <div className="oval oval-4"></div>
            <div className="oval oval-5"></div>
            <div className="oval oval-6"></div>
            <div className="oval oval-7"></div>
          </div>
        </div>
        <FadeInSection>
          <SplitTextSection
            sectionTitle={page.acfHomepage.textSection1.title}
            primaryText={page.acfHomepage.textSection1.leftTitle}
            primaryDarkText={page.acfHomepage.textSection1.leftSubcopy}
            bodyText={page.acfHomepage.textSection1.rightSubcopy}
          />
        </FadeInSection>
        <FadeInSection>
          <SplitTextImageSection
            imageSide="right"
            imageSize="default"
            image={page.acfHomepage.textSection2.image}
            title={page.acfHomepage.textSection2.title}
            subcopy={page.acfHomepage.textSection2.subcopy}
            cta1={page.acfHomepage.textSection2.cta}
            backgroundColorToken={null}
          />
        </FadeInSection>
        <FadeInSection>
          <SplitTextImageSection
            imageSide="left"
            imageSize="default"
            image={page.acfHomepage.textSection3.image}
            title={page.acfHomepage.textSection3.title}
            subcopy={page.acfHomepage.textSection3.subcopy}
            cta1={page.acfHomepage.textSection3.cta}
            backgroundColorToken={null}
          />
        </FadeInSection>
        <FadeInSection>
          <SplitTextImageSection
            imageSide="right"
            imageSize="default"
            image={page.acfHomepage.textSection4.image}
            title={page.acfHomepage.textSection4.title}
            subcopy={page.acfHomepage.textSection4.subcopy}
            cta1={page.acfHomepage.textSection4.cta1}
            cta2={page.acfHomepage.textSection4.cta2}
            backgroundColorToken={null}
          />
        </FadeInSection>
        <div className="homepage__specialty-section content-spacing--xxl">
          <div className="page-wrap">
            <FadeInSection>
              <HomepageDepartmentSection
                title={page.acfHomepage.departmentSection.title}
                departments={page.acfHomepage.departmentSection.departments}
                cta={page.acfHomepage.departmentSection.cta}
              />
            </FadeInSection>
          </div>
        </div>
        <div className="homepage__carousel-section bg-light-gray">
          <div className="page-wrap content-spacing--lg">
            <FadeInSection>
              <PageCarousel
                childKeyIdentifier={"blog-card"}
                contentSlide={
                  <div className="homepage__copy">
                    {page.acfHomepage.hnewsCarousel.title && (
                      <div>
                        <HeadingText
                          className="homepage__title mb-20px"
                          text={language[props.language].news}
                          size="md"
                          element="h2"
                          color="dark"
                        />
                        <HeadingText
                          className="homepage__title mb-40px"
                          text={page.acfHomepage.hnewsCarousel.title}
                          size="xl"
                          element="h2"
                          color="primary"
                        />
                      </div>
                    )}
                    {page.acfHomepage.hnewsCarousel.cta && (
                      <BrandButton
                        className="desktop-lg"
                        href={page.acfHomepage.hnewsCarousel.cta.url}
                        text={page.acfHomepage.hnewsCarousel.cta.title}
                      />
                    )}
                  </div>
                }
                slides={newsItems.map((news, index) => {
                  return (
                    <NewsCard
                      key={`homepage-location-card--${index}`}
                      title={news.title}
                      description={news.acf.metaDescription}
                      image={news.acf.metaImage}
                      date={news.acf.displayDate}
                      uri={news.uri}
                      category={news.acf.category}
                      language={props.language}
                    />
                  )
                })}
              />
            </FadeInSection>
          </div>
        </div>
        <FadeInSection>
          <SplitTextImageSection
            imageSide="left"
            imageSize="default"
            titleHeadingSize="md"
            titleColorToken="dark"
            bodyColorToken="primary"
            bodySize="lg"
            bodyComponent={HeadingText}
            image={page.acfHomepage.textSection5.image}
            title={page.acfHomepage.textSection5.title}
            subcopy={page.acfHomepage.textSection5.subcopy}
            cta1={page.acfHomepage.textSection5.cta}
          />
        </FadeInSection>
        {
          // Career section
        }
        <FadeInSection>
          <SplitTextImageSection
            image={page.acfHomepage.hpcareerSection.image}
            imageSide="right"
            imageSize="default"
            titleHeadingSize="md"
            titleColorToken="dark"
            bodyColorToken="primary"
            bodySize="lg"
            bodyComponent={HeadingText}
            title={page.acfHomepage.hpcareerSection.title}
            subcopy={page.acfHomepage.hpcareerSection.subcopy}
            cta2={page.acfHomepage.hpcareerSection.cta}
          />
        </FadeInSection>
        {/* <pre>{JSON.stringify(page, null, 2)}</pre> */}
      </div>
    </Layout>
  )
}

Homepage.propTypes = {
  language: PropTypes.string,
}

Homepage.defaultProps = {
  language: "es",
}

export const query = graphql`
  query HomepageQuery {
    allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "homepage" } } }) {
      edges {
        node {
          id
          uri
          acfPageMeta {
            metaDescription
            metaImage {
              altText
              sourceUrl
              uri
            }
            metaTitle
          }
          acfHomepage {
            hpageHero {
              image {
                sourceUrl
                altText
              }
              video {
                mimeType
                mediaItemUrl
              }
              cta2 {
                title
                url
              }
              cta1 {
                title
                url
              }
              title
            }
            textSection1 {
              leftSubcopy
              leftTitle
              rightSubcopy
              title
            }
            textSection2 {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta {
                url
                title
              }
            }
            textSection3 {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta {
                url
                title
              }
            }
            textSection4 {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta1 {
                url
                title
              }
              cta2 {
                url
                title
              }
            }
            textSection5 {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta {
                url
                title
              }
            }
            hpcareerSection {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta {
                url
                title
              }
            }
            locationCarousel {
              locations {
                ... on WpLocation {
                  id
                  title
                  uri
                  acf {
                    publishFinal
                    city
                    stateAbbreviation
                    streetAddress
                    telephone
                    type {
                      name
                      id
                      description
                    }
                    map {
                      placeId
                    }
                    zipCode
                    locationImage {
                      altText
                      sourceUrl
                    }
                  }
                }
              }
              title
              cta {
                url
                title
              }
            }
            hnewsCarousel {
              title
              mode
              news {
                ... on WpNewsPost {
                  id
                  title
                  uri
                  acf {
                    publishFinal
                    displayDate
                    metaDescription
                    metaImage {
                      altText
                      sourceUrl
                    }
                    category {
                      ... on WpNewsCategory {
                        id
                        name
                        acfCategory {
                          color
                        }
                      }
                    }
                  }
                }
              }
              cta {
                title
                url
              }
            }
            departmentSection {
              title
              cta {
                title
                url
              }
              departments {
                id
                name
              }
            }
          }
        }
      }
    }
    allWpNewsPost(
      limit: 5
      sort: { fields: acf___displayDate, order: DESC }
      filter: { acf: { publishFinal: { eq: true } } }
    ) {
      edges {
        node {
          id
          title
          uri
          acf {
            displayDate
            metaDescription
            metaImage {
              altText
              sourceUrl
            }
            category {
              ... on WpNewsCategory {
                id
                name
                acfCategory {
                  color
                }
              }
            }
          }
        }
      }
    }
  }
`

const PREVIEW_QUERY = gql`
  query PreviewHomepageQuery($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      preview {
        node {
          id
          uri
          acfPageMeta {
            metaDescription
            metaImage {
              altText
              sourceUrl
              uri
            }
            metaTitle
          }
          acfHomepage {
            hpageHero {
              image {
                sourceUrl
                altText
              }
              video {
                mimeType
                mediaItemUrl
              }
              cta2 {
                title
                url
              }
              cta1 {
                title
                url
              }
              title
            }
            textSection1 {
              leftSubcopy
              leftTitle
              rightSubcopy
              title
            }
            textSection2 {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta {
                url
                title
              }
            }
            textSection3 {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta {
                url
                title
              }
            }
            textSection4 {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta1 {
                url
                title
              }
              cta2 {
                url
                title
              }
            }
            textSection5 {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta {
                url
                title
              }
            }
            hpcareerSection {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta {
                url
                title
              }
            }
            locationCarousel {
              locations {
                ... on Location {
                  id
                  title
                  uri
                  acf {
                    publishFinal
                    city
                    stateAbbreviation
                    streetAddress
                    telephone
                    map {
                      placeId
                    }
                    type {
                      name
                      id
                      description
                    }
                    locationImage {
                      altText
                      sourceUrl
                    }
                    zipCode
                  }
                }
              }
              title
              cta {
                url
                title
              }
            }
            hnewsCarousel {
              title
              mode
              news {
                ... on NewsPost {
                  id
                  title
                  uri
                  acf {
                    publishFinal
                    displayDate
                    metaDescription
                    metaImage {
                      altText
                      sourceUrl
                    }
                    category {
                      ... on NewsCategory {
                        id
                        name
                        acfCategory {
                          color
                        }
                      }
                    }
                  }
                }
              }
              cta {
                title
                url
              }
            }
            departmentSection {
              title
              cta {
                title
                url
              }
              departments {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(Homepage)
