import React from 'react'
import PropTypes from 'prop-types'
import HeadingText from "@components/common/HeadingText"

import "./style.scss"

const ThreeColumnGrid = props => {
  return (<div className='three-column-grid'>
    <div className="three-column-grid__text">
      {
        props.overline && <HeadingText
          className="three-column-grid__overline mb-20px"
          text={props.overline}
          size="md"
          element="h2"
          color="dark"
        />
      }
      {
        props.title && <HeadingText
          className="three-column-grid__title mb-100px"
          text={props.title}
          size="xl"
          element="p"
          color="primary"
        />
      }

    </div>
    <div className="three-column-grid__wrapper">{
      props.items.map(item => {
        return <div className="three-column-grid__item">{item}</div>
      })
    }</div>


    {
      !!props.cta && props.cta
    }
  </div>)
}

ThreeColumnGrid.propTypes = {
  overline: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  cta: PropTypes.object
}
ThreeColumnGrid.defaultProps = {
  items: []
}

export default ThreeColumnGrid