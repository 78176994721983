import React from "react"
import PropTypes from "prop-types"

import classnames from "classnames"

import "./style.scss"

const validElements = ["h1", "h2", "h3", "h4", "p"]

const DisplayText = props => {
  const validDisplayLevels = validElements

  const safeHeading = props.element ? props.element.toLowerCase() : ""
  const DisplayElement = validDisplayLevels.includes(safeHeading)
    ? safeHeading
    : "p"

  return (
    <DisplayElement
      className={classnames(
        "display-text",
        `display-text--${props.size}`,
        `display-text--${props.color}`,
        props.className
      )}
    >
      {props.text}
    </DisplayElement>
  )
}

DisplayText.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "dark",
    "dark-gray",
    "light",
    "primary",
    "primary-light",
    "primary-dark",
    "secondary",
  ]),
  element: PropTypes.oneOf(validElements),
  text: PropTypes.string,
  size: PropTypes.oneOf(["xxl", "xl", "lg"]),
}
DisplayText.defaultProps = {
  color: "dark",
  element: "h2",
  size: "xxl",
}

export default DisplayText
